import React, { useEffect, useState, lazy, Suspense } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryStringProvider } from './Contexts/QueryString';
import { VisitorParamsProvider } from './Contexts/VisitorParams';
import { queryString } from './Utility/QueryString';
import { RedirectProvider } from './Contexts/RedirectContext';
import HelmetBasedOnDomain from './Components/Includes/Layouts/Common/HelmetBasedOnDomain';
import './App.scss';
import LoadingFallback from './Components/Pages/LoadingFallback';

const HomePage = lazy(() => import('./Components/Pages/HomePage'));
const FbPixel = lazy(() => import('./Components/Pages/FbPixel'));
const NotFound = lazy(() => import('./Components/Pages/NotFound'));
const CMSH_V2 = lazy(() => import('./Components/Pages/Splits/CMSH_V2'));
const CMSH_V1 = lazy(() => import('./Components/Pages/Splits/CMSH_V1'));
const CMSH_V1PersonalDetails = lazy(
  () => import('./Components/Pages/CMSH_V1/CMSH_V1PersonalDetails')
);
const CreditAnalysis = lazy(
  () => import('./Components/Pages/CMSH_V1/CreditAnalysis')
);
const Lenders = lazy(() => import('./Components/Pages/CMSH_V1/Lenders'));
const Loader2 = lazy(() => import('./Components/Pages/CMSH_V1/Loader2'));
const ThankYouV1 = lazy(() => import('./Components/Pages/CMSH_V1/ThankYouV1'));
const SignatureV1 = lazy(
  () => import('./Components/Pages/CMSH_V1/SignatureV1')
);
const Duplicate = lazy(() => import('./Components/Pages/CMSH_V1/Duplicate'));
const FollowUp = lazy(() => import('./Components/Pages/Followup/FollowUp'));
const FollowupLoader = lazy(
  () => import('./Components/Pages/Followup/FollowupLoader')
);
const FollowupSignature = lazy(
  () => import('./Components/Pages/Followup/FollowupSignature')
);
const FollowupCreditAnalysis = lazy(
  () => import('./Components/Pages/Followup/FollowupCreditAnalysis')
);
const FollowupLenders = lazy(
  () => import('./Components/Pages/Followup/FollowupLenders')
);
const AdtopiaLoadDynamic = lazy(() => import('./Utility/AdtopiaLoadDynamic'));
const EnvDocView = lazy(() => import('./Components/Pages/Docs/EnvDocView'));
const Thankyou = lazy(() => import('./Components/Pages/Thankyou/Thankyou'));
const EditDetails = lazy(
  () => import('./Components/Pages/CMSH_V1/EditDetails')
);
const CreditCheckEdit = lazy(
  () => import('./Components/Pages/CMSH_V1/CreditCheckEdit')
);
const FollowupEditDetails = lazy(
  () => import('./Components/Pages/Followup/FollowupEditDetails')
);
const FollowupCreditCheckEdit = lazy(
  () => import('./Components/Pages/Followup/FollowupCreditCheckEdit')
);
const PrivacyPolicy = lazy(
  () => import('./Components/Includes/Layouts/Common/PrivacyPolicy')
);
const TermsOfUse = lazy(
  () => import('./Components/Includes/Layouts/Common/TermsOfUse')
);
const CookiePolicy = lazy(
  () => import('./Components/Includes/Layouts/Common/CookiePolicy')
);
const Disclaimers = lazy(
  () => import('./Components/Includes/Layouts/Common/Disclaimers')
);
const LegalNotice = lazy(
  () => import('./Components/Includes/Layouts/Common/LegalNotice')
);
const CarRegistrationV1 = lazy(
  () => import('./Components/Pages/CMSH_V1/CarRegistrationV1')
);
const FollowupCarRegistration = lazy(
  () => import('./Components/Pages/Followup/FollowupCarRegistration')
);
const scheduledMaintenance = React.lazy(
  () => import('./Components/Pages/scheduledMaintenance')
);

// v3
const CMSH_V3 = lazy(() => import('./Components/Pages/CMSH_V3/CMSH_V3'));
const PersonalDetailsV3 = lazy(
  () => import('./Components/Pages/PersonalDetails/PersonalDetailsV3')
);
const SignatureV3 = lazy(
  () => import('./Components/Pages/Signature/SignatureV3')
);
const IdUploadV3 = lazy(() => import('./Components/Pages/IdUpload/IdUploadV3'));
const CreditAnalysisV3 = lazy(
  () => import('./Components/Pages/Creditcheck/CreditAnalysisV3')
);
const Loader3 = lazy(() => import('./Components/Pages/Common/Loader'));
const LenderV3 = lazy(() => import('./Components/Pages/Lenders/LenderV3'));
const ThankyouV3 = lazy(() => import('./Components/Pages/Thankyou/ThankYouV3'));
const ComplaintsPolicy = lazy(
  () => import('./Components/Pages/Common/ComplaintsPolicy')
);
const CarRegistrationV3 = lazy(
  () => import('./Components/Pages/CarRegistration/CarRegistrationV3')
);
const CreditCheckEditV3 = lazy(
  () => import('./Components/Pages/Creditcheck/CreditCheckEditV3')
);
const EditDetailsV3 = lazy(
  () => import('./Components/Pages/EditPage/EditDetailsV3')
);

const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: '' });

  return (
    <>
      <HelmetBasedOnDomain />
      <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <RedirectProvider value={{ redirectUrl }}>
            <BrowserRouter>
              <Suspense fallback={<LoadingFallback />}>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/fbpixel" component={FbPixel} />
                <Route exact path="/404" component={NotFound} />
                <Route exact path="/CMSH_V2" component={CMSH_V2} />
                <Route exact path="/CMSH_V1" component={CMSH_V1} />
                <Route
                  exact
                  path="/personal-details"
                  component={CMSH_V1PersonalDetails}
                />
                <Route exact path="/V2/thankyou" component={Thankyou} />
                <Route exact path="/V1/signature" component={SignatureV1} />
                <Route
                  exact
                  path="/V1/credit-check"
                  component={CreditAnalysis}
                />
                <Route exact path="/V1/lenders" component={Lenders} />
                <Route exact path="/V1/loader" component={Loader2} />
                <Route exact path="/V1/thankyou" component={ThankYouV1} />
                <Route exact path="/duplicate" component={Duplicate} />
                <Route exact path="/followup" component={FollowUp} />
                <Route
                  exact
                  path="/followup-loader"
                  component={FollowupLoader}
                />
                <Route
                  exact
                  path="/followup-lenders"
                  component={FollowupLenders}
                />
                <Route
                  exact
                  path="/followup-signature"
                  component={FollowupSignature}
                />
                <Route
                  exact
                  path="/followup-credit-analysis"
                  component={FollowupCreditAnalysis}
                />
                <Route exact path="/edit-details" component={EditDetails} />
                <Route
                  exact
                  path="/edit-credit-check"
                  component={CreditCheckEdit}
                />
                <Route
                  exact
                  path="/followup-edit-details"
                  component={FollowupEditDetails}
                />
                <Route
                  exact
                  path="/followup-edit-credit-check"
                  component={FollowupCreditCheckEdit}
                />
                <Route exact path="/cookie-Policy" component={CookiePolicy} />
                <Route exact path="/disclaimers" component={Disclaimers} />
                <Route exact path="/legal-notice" component={LegalNotice} />
                <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                <Route exact path="/terms-of-use" component={TermsOfUse} />
                <Route
                  exact
                  path="/V1/car-registration"
                  component={CarRegistrationV1}
                />
                <Route
                  exact
                  path="/followup-car-registration"
                  component={FollowupCarRegistration}
                />
                {/* v3 */}
                <Route exact path="/CMSH_V3" component={CMSH_V3} />
                <Route
                  exact
                  path="/V3/personal-details"
                  component={PersonalDetailsV3}
                />
                <Route exact path="/V3/signature" component={SignatureV3} />
                <Route exact path="/V3/lenders" component={LenderV3} />
                <Route exact path="/V3/id-upload" component={IdUploadV3} />
                <Route exact path="/V3/loader" component={Loader3} />
                <Route
                  exact
                  path="/V3/credit-check"
                  component={CreditAnalysisV3}
                />
                <Route exact path="/V3/thankyou" component={ThankyouV3} />
                <Route exact path="/complaints" component={ComplaintsPolicy} />
                <Route
                  exact
                  path="/V3/car-registration"
                  component={CarRegistrationV3}
                />
                <Route
                  exact
                  path="/V3/edit-details"
                  component={EditDetailsV3}
                />

                <Route
                  exact
                  path="/V3/edit-credit-check"
                  component={CreditCheckEditV3}
                />
                <Route
                  exact
                  path={['/google', '/testing']}
                  component={AdtopiaLoadDynamic}
                />
                <Route
                  exact
                  path="/doc/:docType/:filename"
                  component={EnvDocView}
                />
                <Route
                  exact
                  path="/scheduled-maintenance"
                  component={scheduledMaintenance}
                />
              </Suspense>
            </BrowserRouter>
          </RedirectProvider>
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};

export default App;
