import { Helmet } from 'react-helmet';
import faviconfastpcp from '../../../../assets/img/Logos/fast_pcp_new/fp-favicon.png';
import faviconyourfreecheck from '../../../../assets/img/Logos/your_free_check_new/fav-icon.png';
import faviconrclaimshero from '../../../../assets/img/Logos/claim_hero/favicon.ico';

const HelmetBasedOnDomain = () => {
  const domainName = window.location.hostname;
  let favicon;
  let brandName;
  if (
    domainName === 'localhost' ||
    domainName === 'dev.fastpcp.com' ||
    domainName === 'pre.fastpcp.com' ||
    domainName === 'fastpcp.com'
  ) {
    // favicon = faviconfastpcp;
    // brandName = 'Fast PCP';
    favicon = faviconrclaimshero;
    brandName = 'ClaimsHero';
  } else if (
    domainName === 'dev.yourfreecheck.co.uk' ||
    domainName === 'pre.yourfreecheck.co.uk' ||
    domainName === 'yourfreecheck.co.uk'
  ) {
    // favicon = faviconyourfreecheck;
    // brandName = 'Your Free Check';
    favicon = faviconrclaimshero;
    brandName = 'ClaimsHero';
  } else if (
    domainName === 'dev.refunds.claimshero.io' ||
    domainName === 'pre.refunds.claimshero.io' ||
    domainName === 'refunds.claimshero.io'
  ) {
    favicon = faviconrclaimshero;
    brandName = 'ClaimsHero';
  } else if (
    domainName === 'dev.start.claimshero.io' ||
    domainName === 'pre.start.claimshero.io' ||
    domainName === 'start.claimshero.io'
  ) {
    favicon = faviconrclaimshero;
    brandName = 'ClaimsHero';
  } else {
    favicon = faviconrclaimshero;
    brandName = 'ClaimsHero';
  }
  return (
    <Helmet>
      <link rel="icon" href={favicon} />
      <title>{brandName}</title>
    </Helmet>
  );
};

export default HelmetBasedOnDomain;
